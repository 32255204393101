<!-- 文件中文名: 推荐网络图(图形) -->
<template>
  <div class="network-pic app-container">
    <div class="page-container account">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <!--经销商编号-->
          <el-form-item :label="$t('miMemberNetwork.memberNo')+':'">
            <el-input   @input="value=>listQuery.memberNo=value.toUpperCase()"  v-model.trim="listQuery.memberNo" size="mini"/>
          </el-form-item>
          <el-form-item>
            <!--搜索-->
            <el-button :loading="loading" icon="el-icon-search" plain type="primary" @click="handleFilter">
              {{ $t('operation.button.search') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="width: 100%;">
        <organization-chart
            v-show="Object.keys(viewData).length"
            v-loading="loading"
            :datasource="viewData"
            :pan="true"
            :zoom="true"
            :zoomin-limit="2"
            :zoomout-limit="0.5"
        >
          <template slot-scope="{ nodeData }">
            <div class="orgchartnnode" :class="colorOne[nodeData.levelType ? (nodeData.levelType).substr(0,1) : '']">
              <p style="margin: 0;color: blue;text-decoration: underline;cursor: pointer"
                  @click="getClickData(nodeData.memberNo)">
                {{ nodeData.memberNo }}
              </p>
              <p style="margin: 0;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100px;margin-left: 30%">
                {{ nodeData.memberName }}
              </p>
              <div style="margin: 3px 20px">
                <div  v-for="(i,index) in nodeData.subordinate" :key="i.levelType" >
                  <div :class="color[index]" style="margin: 0;padding: 5px">{{$lt(initDict.levelType ,i.levelType)}} - {{$t('rpt.memCount')+':'}}{{ i.personNum }}</div>
                </div>
              </div>
              <p style="margin: 5px">{{$t('busi.poMemberOrder.total.pv')+':'}}{{ nodeData.netTotalFv }}</p>
              <p style="margin: 5px">{{ nodeData.registerDate }}</p>
            </div>
          </template>
        </organization-chart>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex"
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import { getPositionViews2 } from "@/api/rus/api"

export default {
  name: 'NetworkPic',
  components: { OrganizationChart },
  data() {
    return {
      colorOne:['levelType0','levelType1','levelType2','levelType3','levelType4'],
      color:['levelType4','levelType3','levelType2','levelType1', 'levelType0'],
      loading: false,
      viewData: {},
      defaultProps: {
        children: 'children',
        label: 'memberNo',
      },
      listQuery: {
        upOrDown: 'down',
        memberNo: '',
        treeOrView: 'View',
        Layer: 2
      },
      initDict: {
        levelType:'ru.member.level'
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCode'
    ])
  },
  mounted() {
    this.listQuery.memberNo = this.userCode
  },
  methods: {
    handleFilter() {
      if (!this.listQuery.memberNo) {
        return false
      }
      this.loading = true
      getPositionViews2({...this.listQuery}).then(res => {
        this.loading = false
        this.viewData = res.data.data
      }).catch(err => {
        this.loading = false
        console.warn(err)
      })
    },
    // eslint-disable-next-line no-unused-vars
    getClickData(memberNo) {
      this.loading = true
      getPositionViews2({...this.listQuery, memberNo: memberNo}).then(res => {
        this.loading = false
        this.viewData = res.data.data
      }).catch(err => {
        this.loading = false
        console.warn(err)
      })
    }
  }
}

</script>
<style scoped lang="scss">
  ::v-deep .orgchart{
    width: 100%;
    background-size:0 0;
    .node{
      width: 200px;
      margin-right: 10px;
    }
  }
  .orgchart-container {
    width: 100%;
    height: 100%;
    border: 0;
  }
  .orgchartnnode p {
    padding: 0;
    margin: 0;
  }

</style>

<style scoped>
.levelType0{
  background-image: linear-gradient(#ffffff, #f7f7f7);
}
.levelType1{
  background-image: linear-gradient(#cdcdcd, #eaedf1);
}
.levelType2{
  background-image: linear-gradient(#50d2e7,#4dd5ed);
}
.levelType3{
  background-image: linear-gradient(#f1dc99,#e1c063);
}
.levelType4{
  background-image: linear-gradient(#f4748f,#ffa8af);
}

/deep/ .orgchart .node{
  width: 210px !important;
}
/deep/ .orgchart{
  cursor: default; transform: matrix(0.8, 0, 0, 0.8, -86, -88);
}
</style>
